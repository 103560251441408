import { LetterPress } from "../types/LetterPress";
import UserStats from "../types/UserStats";
import { request } from "./requests";
import { getDeviceId, getThumbprint } from "./system";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = async (path: string, body: any) =>
  await request("POST", path, body);

const subscribe = async (
  pushSubscription: PushSubscription,
  timeZone: string
) => await post("/v1/subscribe", { subscription: pushSubscription, timeZone });

const finishGame = async (
  gameNumber: number,
  gameHash: string,
  letterPresses: LetterPress[],
  stats: UserStats,
  localTime: string,
  localTimeZone: string,
  duration: number | null,
  isArchive: boolean = false,
  won: boolean
) =>
  await post("/v2/finishGame", {
    deviceId: getDeviceId(),
    duration,
    gameHash,
    gameNumber,
    isArchive,
    letterPresses,
    localTime,
    localTimeZone,
    stats,
    thumbprint: getThumbprint(),
    won,
  });

type StatsResponse = {
  data: {
    solvedRate?: number;
    userPercentile?: number;
    gameIndex?: number;
    userStats?: UserStats;
  };
};

const getStats = async (gameIndex: number) => {
  return (await request(
    "GET",
    `/v2/stats/${gameIndex}`,
    null
  )) as StatsResponse;
};

const sendProgress = async (
  gameIndex: number,
  gameHash: string,
  letterPresses: LetterPress[],
  localTime: string,
  localTimeZone: string,
  isArchive: boolean = false
) =>
  await post("/v1/progress", {
    deviceId: getDeviceId(),
    gameHash,
    gameIndex,
    isArchive,
    letterPresses,
    localTime,
    localTimeZone,
  });

const postShareEvent = async (gameIndex: number, shareMethod: string) =>
  await post("/v1/share", { gameIndex, shareMethod });

export const postProfile = async (email: string) =>
  await post("/v2/profile", { email });

export { subscribe, finishGame, getStats, postShareEvent, sendProgress };
