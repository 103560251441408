import { selectPuzzle } from "../app/gameSlice";
import { useAppSelector } from "../hooks";

export const PuzzleLink = () => {
  const { link } = useAppSelector(selectPuzzle);

  if (!link) {
    return null;
  }

  return (
    <div className="text-center mt-2 rounded-md p-2 m-3 bg-opacity-5 bg-slate-900 dark:bg-white dark:bg-opacity-5 text-sm">
      ➡️{" "}
      <a href={link.url} className="underline font-bold">
        {link.text}
      </a>
    </div>
  );
};
