import { combineReducers, configureStore } from "@reduxjs/toolkit";
import gameReducer from "./gameSlice";
import toastReducer from "./toastSlice";
import settingsReducer from "./settingsSlice";
import featuresSlice from "./featuresSlice";
import statsReducer from "./statsSlice";
import pageReducer from "./pageSlice";
import messagesReducer from "./messagesSlice";
import modalReducer from "./modalSlice";
import learningReducer from "./learningSlice";
import systemReducer from "./systemSlice";
import migrationReducer from "./migrationSlice";
import profileReducer from "./profileSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import { registerObservers } from "./observers";
import { actionLogger } from "../helpers/tracing";

const rootReducer = combineReducers({
  features: featuresSlice,
  game: gameReducer,
  learning: learningReducer,
  messages: messagesReducer,
  migration: migrationReducer,
  modal: modalReducer,
  page: pageReducer,
  profile: profileReducer,
  settings: settingsReducer,
  stats: statsReducer,
  system: systemReducer,
  toast: toastReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .prepend(actionLogger),
    preloadedState,
    reducer: rootReducer,
  });
};

export const store = setupStore();

registerObservers();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
