import { createListenerMiddleware, addListener } from "@reduxjs/toolkit";
import type { TypedStartListening, TypedAddListener } from "@reduxjs/toolkit";

import type { RootState, AppDispatch } from "./store";
import { registerGameListeners } from "./gameListener";
import registerSharingListeners from "./sharingListener";
import { registerTrackingListeners } from "./trackingListener";
import { registerStatsListeners } from "./statsListener";
import registerLearningListeners from "./learningListener";
import { registerNavigationListener } from "./navigationListener";
import { registerMigrationListener } from "./migrationListener";
import { registerProfileListener } from "./profileListener";

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

registerGameListeners();
registerSharingListeners();
registerTrackingListeners();
registerStatsListeners();
registerLearningListeners();
registerNavigationListener();
registerMigrationListener();
registerProfileListener();
