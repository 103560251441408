import { getClassName } from "../helpers/classNames";
import SpinnerIcon from "./icons/SpinnerIcon";

export type ButtonVariant = "primary" | "secondary";

type Props = {
  id?: string;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
  variant?: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
};

const Button = ({
  id,
  onClick,
  children,
  className,
  variant = "primary",
  loading = false,
  disabled = false,
}: Props) => (
  <button
    id={id}
    className={`border-2 transition-all px-4 py-2 text-base font-semibold rounded-md shadow-sm items-center ${
      className ?? ""
    } ${getClassName(variant, disabled)}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
    {loading && (
      <SpinnerIcon className="animate-spin fill-white ml-2.5 inline-block align-middle" />
    )}
  </button>
);

export default Button;
