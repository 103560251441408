import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ProfilePayload = {
  email: string;
};

type ProfileState = {
  email?: string;
  isUpdating: boolean;
  updateError: boolean;
  updateResult: string | null;
};

const initialState: ProfileState = {
  email: "",
  isUpdating: false,
  updateError: false,
  updateResult: null,
};

const profileSlice = createSlice({
  initialState,
  name: "profile",
  reducers: {
    startUpdate(state) {
      state.isUpdating = true;
      state.updateResult = null;
      state.updateError = false;
    },
    updateFailure(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.updateResult = action.payload;
      state.updateError = true;
    },
    updateProfile(state, action: PayloadAction<ProfilePayload>) {
      state.email = action.payload.email;
    },
    updateSuccess(state, action: PayloadAction<string>) {
      state.isUpdating = false;
      state.updateResult = action.payload;
      state.updateError = false;
    },
  },
});

export const selectProfile = (state: { profile: ProfileState }) =>
  state.profile;
export const selectIsProfileUpdating = createSelector(
  selectProfile,
  (profile) => profile.isUpdating
);
export const selectProfileUpdateResult = createSelector(
  selectProfile,
  (profile) => profile.updateResult
);
export const selectProfileUpdateError = createSelector(
  selectProfile,
  (profile) => profile.updateError
);

export const { startUpdate, updateSuccess, updateFailure, updateProfile } =
  profileSlice.actions;
export default profileSlice.reducer;
