import { ButtonVariant } from "../components/Button";

export const getClassName = (
  variant: ButtonVariant | undefined,
  disabled: boolean
) => {
  const classNames = [];

  switch (variant) {
    case "primary":
      classNames.push(
        "primary bg-orange-500 dark:bg-orange-600 border-orange-500 dark:border-orange-600 text-white"
      );
      if (!disabled) {
        classNames.push(
          "active:bg-orange-600 dark:active:bg-orange-700 active:border-orange-600 dark:active:border-orange-700"
        );
      }

      break;
    case "secondary":
      classNames.push(
        "secondary box-border border-orange-500 text-orange-500 dark:border-orange-500 dark:text-orange-500"
      );
      if (!disabled) {
        classNames.push(
          "active:bg-orange-400 active:border-orange-400 active:text-white dark:active:bg-orange-900 dark:active:border-orange-900 dark:active:text-gray-300"
        );
      }
      break;
  }

  if (disabled) {
    classNames.push("cursor-not-allowed opacity-50");
  }

  return classNames.join(" ");
};
